<div class="child-item" (click)="clickItem()">
  <div class="item-info">
    <div>{{ item?.name }}</div>
    <div *ngIf="item && item.price > 0" class="price">
      +${{ item.price | number: "1.2" }}
    </div>
  </div>
  <div *ngIf="!staticMode">
    <div *ngIf="selected" class="box-selected">&#x2713;</div>
    <div *ngIf="!selected && !allowNewSelections" class="box-disabled"></div>
    <div *ngIf="!selected && allowNewSelections" class="box-unselected"></div>
  </div>
</div>
