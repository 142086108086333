<div class="child-item" (click)="clickItem()">
  <div class="top">
    <div class="name">{{ item?.name }}</div>
    <div class="right">
      <div *ngIf="item && item.price > 0" class="price">
        +${{ item.price | number: "1.2" }}
      </div>
      <div *ngIf="!staticMode">
        <div *ngIf="selected" class="box-selected">&#x2713;</div>
        <div
          *ngIf="!selected && !allowNewSelections"
          class="box-disabled"
        ></div>
        <div
          *ngIf="!selected && allowNewSelections"
          class="box-unselected"
        ></div>
      </div>
    </div>
  </div>
  <div *ngIf="item?.description" class="bottom">
    {{ item?.description }}
  </div>
</div>
