<div class="home">
  <div class="header">
    <div class="logo" [routerLink]="['app.grandemenus.com']">
      <img src="" />
    </div>
    <div class="links">
      <div>About us</div>
      <div>Contact</div>
    </div>
  </div>
  <div class="body">
    <img src="../../assets/favicon.svg" class="main-image" />
    <div class="title">
      To view our available menus, please go to our
      <span class="directory" [routerLink]="['./directory']">directory</span>
    </div>
  </div>
  <div class="footer">Copyright 2022</div>
</div>
