import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from '../models/item.model';
import { Modifier } from '../models/modifier.model';
import { SavedModifier } from '../models/savedModifier.model';

@Component({
  selector: 'app-detailed-modifier',
  templateUrl: './detailed-modifier.component.html',
  styleUrls: ['./detailed-modifier.component.scss'],
})
export class DetailedModifierComponent {
  savedChildItems: Item[] = [];

  @Input()
  modifier: Modifier | null = null;

  @Output()
  savedModifierEvent = new EventEmitter<SavedModifier>();

  itemDeselected(id: string) {
    if (this.modifier) {
      this.savedChildItems = this.savedChildItems.filter(
        (item) => item.id !== id
      );
      this.savedModifierEvent.emit({
        name: this.modifier.name,
        childItems: this.savedChildItems,
        quotaFulfilled: this.savedChildItems.length >= this.modifier.min,
      });
    }
  }

  itemSelected(item: Item) {
    if (this.modifier) {
      this.savedChildItems.push(item);
      this.savedModifierEvent.emit({
        name: this.modifier.name,
        childItems: this.savedChildItems,
        quotaFulfilled: this.savedChildItems.length >= this.modifier.min,
      });
    }
  }
}
