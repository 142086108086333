<div class="header">
  <div class="container">
    <div class="back" (click)="navigateBack()">
      <fa-icon [icon]="angleLeft" class="arrow"></fa-icon>
    </div>
    <div
      class="saved"
      (click)="openSavedItemsOverlay()"
      *ngIf="!staticMode && savedItems.length > 0"
    >
      <div>
        <fa-icon [icon]="solidHeart"></fa-icon>
      </div>
    </div>
  </div>
</div>
<div class="page-content" (touchstart)="resetTimer()">
  <div class="image-row">
    <img
      class="large-image"
      *ngIf="item && item.image && item.image.length > 0"
      [src]="item.image"
    />
    <div
      *ngIf="(!staticMode && !item) || !item.image || item.image.length < 1"
      class="add-image"
      (click)="openPhotoAddModal()"
    >
      <img
        class="add-photo-icon"
        src="../../../assets/BlackAddPictureIcon.svg"
      />
      <span>add a photo</span>
    </div>
    <div
      class="extra-margin"
      *ngIf="!item || !item.image || item.image.length < 1"
    ></div>
  </div>

  <div class="item-content">
    <div class="item-header">
      <div class="item-title">{{ item.name }}</div>
      <fa-icon
        *ngIf="!staticMode && !itemIsSaved"
        [icon]="emptyHeart"
        (click)="saveItem()"
      ></fa-icon>
      <fa-icon
        class="solid-heart"
        *ngIf="!staticMode && itemIsSaved"
        [icon]="solidHeart"
        (click)="openSavedItemsOverlay()"
      ></fa-icon>
    </div>
    <div class="item-description">{{ item.description }}</div>
    <div class="price-row">
      <div class="item-price" *ngIf="item && totalPrice > 0">
        ${{ totalPrice | number: "1.2" }}
      </div>
      <div class="tags">
        <div class="tag" *ngIf="item.isGF">GF</div>
        <div class="tag" *ngIf="item.isDF">DF</div>
        <div class="tag" *ngIf="item.isVegan">V</div>
      </div>
    </div>

    <div class="item-modifiers"></div>
  </div>

  <div class="sub-item-content">
    <div *ngFor="let modifier of item.child_modifiers" class="modifier">
      <app-modifier
        *ngIf="!item.detailedItem"
        [modifier]="modifier"
        (savedModifierEvent)="updateSavedModifier($event)"
      ></app-modifier>
      <app-detailed-modifier
        *ngIf="item.detailedItem === true"
        [modifier]="modifier"
        (savedModifierEvent)="updateSavedModifier($event)"
      ></app-detailed-modifier>
    </div>
  </div>
  <div class="save-button" *ngIf="!staticMode && !itemIsSaved">
    <button (click)="saveItem()">Save for easy ordering</button>
  </div>
  <div class="save-button" *ngIf="!staticMode && itemIsSaved">
    <button (click)="openSavedItemsOverlay()">View Saved Items</button>
  </div>

  <app-saved-items
    *ngIf="showSavedItemsOverlay"
    (closeOverlayEvent)="closeSavedItemsOverlay()"
  ></app-saved-items>
</div>
<div
  *ngIf="addPhotoModalOpen"
  class="add-photo-modal"
  (click)="closeAddPhotoModule()"
>
  <app-photo-uploader
    [itemId]="item.id"
    (closeModalEvent)="closeAddPhotoModule()"
  ></app-photo-uploader>
</div>
