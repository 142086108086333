<!-- <div class="formatter">
  <div class="data-holders">
    <div class="data-format">
      <textarea
        type="text"
        [formControl]="dataFormControl"
        class="data-input"
        placeholder="Paste data here"
      ></textarea>
      <button (click)="formatData()">Format</button>
    </div>


  </div>
</div> -->

<div class="formatter">
  <form class="form" (ngSubmit)="formatData()" #f="ngForm">
    <div class="restaurant-info">
      <label for="name">Restaurant Name:</label>
      <input type="text" id="name" name="name" ngModel />
    </div>
    <div class="json">
      <textarea
        type="text"
        class="data-input"
        placeholder="Paste JSON here"
        id="json"
        name="json"
        ngModel
      ></textarea>
    </div>

    <button type="submit">Format Data</button>
  </form>
  <div class="data-formatted">{{ formattedData }}</div>
</div>
