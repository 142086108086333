import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MenuService } from '../menu.service';
import { Category } from '../models/category.model';
import {
  faAngleLeft,
  faAngleRight,
  faChevronRight,
  faChevronUp,
  faCamera,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Item } from '../models/item.model';
import { Subject } from 'rxjs';
import { SavedItem } from '../models/savedItem.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnDestroy {
  @Input()
  category!: Category;

  showSavedItemsOverlay = false;
  savedItems: SavedItem[] = [];
  showBack: boolean = true;
  logo: string = '';
  name: string = '';

  angleLeft = faAngleLeft;
  angleRight = faAngleRight;
  timesCircle = faTimesCircle;
  camera = faCamera;
  chevronRight = faChevronRight;
  chevronDown = faChevronDown;
  chevronUp = faChevronUp;
  showHeaderTitle: boolean = false;
  expanded: boolean = false;
  addPhotoModalOpen: boolean = false;
  imageItemId: string;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    const element = document.getElementById('category-name');
    if (element) {
      const height = element.offsetHeight;
      this.showHeaderTitle = window.scrollY > height;
    }
  }

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected menuService: MenuService,
    protected location: Location,
    protected gtmService: GoogleTagManagerService
  ) {}

  colors = [
    [136, 67, 12],
    [186, 127, 24],
    [125, 181, 42],
    [51, 132, 109],
    [57, 115, 101],
    [129, 176, 76],
    [135, 100, 138],
    [94, 6, 156],
    [169, 67, 133],
    [96, 130, 175],
    [161, 16, 118],
    [81, 130, 17],
    [158, 126, 149],
  ];

  async ngOnInit(): Promise<void> {
    if (this.menuService.expandedCategories.indexOf(this.category.id) > -1) {
      this.expanded = true;
    }
    let index = 0;
    while (!this.category.image && index < this.category.items.length) {
      if (this.category.items[index].image) {
        this.category.image = this.category.items[index].image;
      }
      index++;
    }
    this.category.items = this.category.items
      .filter((item) => item.image && item.image.length > 0)
      .concat(
        this.category.items.filter(
          (item) => !item.image || item.image.length === 0
        )
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateBack() {
    this.menuService.returnToPreviousCategory();
    this.location.back();
  }

  selectItem(item: Item) {
    this.menuService.addScrollPosition(window.scrollY);
    this.router.navigate([this.router.url, 'item']);
  }

  selectCategory(category: Category) {
    this.menuService.addScrollPosition(window.scrollY);
    this.menuService.currentCategory = category;
    const route = this.router.url.split('/');
    route.pop();
    const newRoute = route.join('/');
    this.router.navigate([newRoute, category.id]);
  }

  openSavedItemsOverlay() {
    this.showSavedItemsOverlay = true;
  }

  closeSavedItemsOverlay() {
    this.showSavedItemsOverlay = false;
  }

  toggleExpansion() {
    if (this.expanded) {
      this.menuService.removeExpandedCategory(this.category.id);
    } else {
      this.menuService.addExpandedCategory(this.category.id);
    }
    this.expanded = !this.expanded;
  }

  navigateToItem(item: Item) {
    const tag = {
      event: 'view_item',
      currency: 'USD',
      value: item.price,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
        },
      ],
    };
    this.gtmService.pushTag(tag);
    this.menuService.addScrollPosition(window.scrollY);
    this.menuService.currentItem = item;
    this.router.navigate([this.router.url.split('?')[0], 'item']);
  }

  openPhotoAddModal(event: MouseEvent, itemId: string) {
    event.stopPropagation();
    this.addPhotoModalOpen = true;
    this.imageItemId = itemId;
  }

  closeAddPhotoModule() {
    this.addPhotoModalOpen = false;
    this.imageItemId = '';
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  returnToTop() {
    const element = document.getElementById(this.category.id);
    element?.scrollIntoView({ behavior: 'smooth' });
  }
}
