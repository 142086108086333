import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChildItemComponent } from '../child-item/child-item.component';
import { MenuService } from '../menu.service';
import { Item } from '../models/item.model';

@Component({
  selector: 'app-detailed-child-item',
  templateUrl: './detailed-child-item.component.html',
  styleUrls: ['./detailed-child-item.component.scss'],
})
export class DetailedChildItemComponent implements OnInit {
  @Output()
  itemSelectedEvent: EventEmitter<Item> = new EventEmitter<Item>();

  @Output()
  itemDeselectedEvent: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  item: Item | null = null;

  @Input()
  allowNewSelections: boolean = true;

  selected: boolean = false;

  staticMode: boolean;

  constructor(protected menuService: MenuService) {}

  ngOnInit() {
    this.menuService.staticMode$.subscribe((mode) => {
      this.staticMode = mode;
    });
  }

  clickItem(): void {
    if (!this.allowNewSelections) {
      if (this.selected && this.item) {
        this.selected = false;
        this.itemDeselectedEvent.emit(this.item.id);
      }
    } else {
      this.selected = !this.selected;
      if (this.selected && this.item) {
        this.itemSelectedEvent.emit(this.item);
      }
      if (!this.selected && this.item) {
        this.itemDeselectedEvent.emit(this.item.id);
      }
    }
  }
}
