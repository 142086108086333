import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { Menu } from '../models/menu.model';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Category } from '../models/category.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavedItem } from '../models/savedItem.model';
import { faHeart, faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  menuData: Menu | null = null;
  angleRight = faAngleRight;
  savedItems: SavedItem[] | null = null;
  showSavedItemsOverlay: boolean = false;
  hasSubCategories: boolean = false;
  currentCategory: Category | null = null;
  returnToHome: boolean = false;
  solidHeart = faHeart;
  bars = faBars;
  introMessage: string | null;
  staticMode: boolean;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected menuService: MenuService,
    protected route: ActivatedRoute,
    protected ref: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    const curentCategoryFromStorage =
      window.sessionStorage.getItem('currentCategory');
    if (curentCategoryFromStorage) {
      this.menuService.currentCategory = JSON.parse(curentCategoryFromStorage);
    }
    const id = this.route.snapshot.paramMap.get('restaurantId');
    if (
      id &&
      (this.menuService.currentRestaurant !== id ||
        !this.menuService.currentMenu)
    ) {
      await this.menuService.retrieveMenuFromS3(id);
    }

    this.menuService.currentMenu$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menu) => {
        this.menuData = menu;
        this.introMessage = this.menuData?.introMessage || null;

        menu?.categories.forEach((category) => {
          if (
            category.sub_categories?.length > 0 &&
            !window.sessionStorage.getItem('currentCategory')
          ) {
            this.hasSubCategories = true;
          }
        });
      });

    this.menuService.savedItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((items) => {
        this.savedItems = items;
      });

    this.menuService.currentCategory$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((category) => {
        this.currentCategory = category;
      });

    this.menuService.returnToHome$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bool) => {
        this.returnToHome = bool;
      });

    this.route.queryParams.subscribe((params) => {
      if (params.static && params.static === 'true') {
        this.menuService.setStaticMode();
      }
    });

    this.menuService.staticMode$.subscribe((mode) => {
      this.staticMode = mode;
    });

    setInterval(() => {
      console.log('refresh');
      if (this.staticMode) {
        location.reload();
      }
    }, 300000);

    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateToCategory(category: Category) {
    this.menuService.currentCategory = category;
    this.hasSubCategories = false;
    this.menuService.returnToHome = true;
    category.sub_categories.forEach((sub) => {
      if (sub.sub_categories.length > 0) {
        this.hasSubCategories = true;
      }
    });
  }

  resetTimer() {
    clearInterval();
    setInterval(() => {
      location.reload();
    }, 300000);
  }

  openSavedItemsOverlay() {
    this.showSavedItemsOverlay = true;
  }

  closeSavedItemsOverlay() {
    this.showSavedItemsOverlay = false;
  }

  returnToHomeMenu() {
    window.sessionStorage.removeItem('currentCategory');
    this.hasSubCategories = true;
    this.menuService.returnToHome = false;
    this.menuService.currentCategory = null;
  }
}
