import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImageService } from '../image.service';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-photo-uploader',
  templateUrl: './photo-uploader.component.html',
  styleUrls: ['./photo-uploader.component.scss'],
})
export class PhotoUploaderComponent {
  @Input()
  itemId!: string;

  successfulUpload: boolean = false;
  loading: boolean = false;

  @Output()
  closeModalEvent: EventEmitter<null> = new EventEmitter<null>();

  selectedImage: File | Blob;

  constructor(
    protected imageService: ImageService,
    protected menuService: MenuService
  ) {}

  imageChanged(event: any) {
    this.selectedImage = event.target.files[0];
  }

  async uploadImage() {
    const restaurantId = this.menuService.currentRestaurant || '';
    const itemId = this.itemId;
    const data = this.selectedImage;
    this.loading = true;
    const newImageLoction = await this.imageService.uploadFile(
      restaurantId,
      itemId,
      data
    );
    this.loading = false;
    this.successfulUpload = true;
    let tempMenu = this.menuService.currentMenu;
    tempMenu?.categories.forEach((cat) => {
      cat.items.forEach((item) => {
        if (item.id === itemId) {
          item.image = newImageLoction;
        }
      });
    });
    this.menuService.currentMenu = tempMenu;
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  closeAddPhotoModal(event: MouseEvent) {
    event.stopPropagation();
    this.closeModalEvent.emit();
  }
}
