import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { CategoryComponent } from './category/category.component';
import { DescriptionTextPipe } from './category/descriptionText.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LandingComponent } from './landing/landing.component';
import { ItemComponent } from './item/item.component';
import { RouterModule } from '@angular/router';
import { SavedItemsComponent } from './saved-items/saved-items.component';
import { ModifierComponent } from './modifier/modifier.component';
import { ChildItemComponent } from './child-item/child-item.component';
import { ContentCombinerComponent } from './content-combiner/content-combiner.component';
import { PriceWithModifiersPipe } from './saved-items/price-with-modifiers.pipe';
import { PhotoUploaderComponent } from './photo-uploader/photo-uploader.component';
import { DetailedModifierComponent } from './detailed-modifier/detailed-modifier.component';
import { DetailedChildItemComponent } from './detailed-child-item/detailed-child-item.component';

@NgModule({
  declarations: [
    HomeComponent,
    CategoryComponent,
    DescriptionTextPipe,
    LandingComponent,
    ItemComponent,
    SavedItemsComponent,
    ModifierComponent,
    ChildItemComponent,
    ContentCombinerComponent,
    PriceWithModifiersPipe,
    PhotoUploaderComponent,
    DetailedModifierComponent,
    DetailedChildItemComponent,
  ],
  imports: [CommonModule, FontAwesomeModule, RouterModule],
  exports: [HomeComponent, CategoryComponent, DescriptionTextPipe],
})
export class MenuModule {}
