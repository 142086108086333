import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  restaurants: any[] = [];

  protected unsubscribe$ = new Subject<void>();

  constructor(protected router: Router, protected menuService: MenuService) {}

  async ngOnInit(): Promise<void> {
    this.menuService.restaurants$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.restaurants = data;
      });
    this.menuService.getAllAvailableRestaurants();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  routeToMenu(restaurantId: string) {
    this.router.navigateByUrl(restaurantId + '/menu');
  }
}
