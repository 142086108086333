import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuModule } from './menu/menu.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormatterComponent } from './formatter/formatter/formatter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstructionsComponent } from './instructions/instructions.component';

@NgModule({
  declarations: [AppComponent, FormatterComponent, InstructionsComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MenuModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
  ],
  providers: [{ provide: 'googleTagManagerId', useValue: 'GTM-KXRLRS7' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    protected router: Router,
    protected viewportScroller: ViewportScroller
  ) {}
}
