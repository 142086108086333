import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from '../menu.service';
import { Item } from '../models/item.model';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { SavedItem } from '../models/savedItem.model';
import { Router } from '@angular/router';
import { faCross } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-saved-items',
  templateUrl: './saved-items.component.html',
  styleUrls: ['./saved-items.component.scss'],
})
export class SavedItemsComponent implements OnInit, OnDestroy {
  savedItems: SavedItem[] = [];
  unsubscribe$ = new Subject<void>();
  timesCircle = faTimesCircle;
  close = faCross;

  @Output()
  closeOverlayEvent = new EventEmitter<boolean>();

  constructor(protected menuService: MenuService, protected router: Router) {}

  ngOnInit(): void {
    this.menuService.savedItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((items) => {
        this.savedItems = items;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeOverlay(event: MouseEvent) {
    event.stopPropagation();
    this.closeOverlayEvent.emit(true);
  }

  removeItem(index: number) {
    const items = [...this.menuService.savedItems];
    items.splice(index, 1);
    this.menuService.savedItems = items;
  }

  clearAll() {
    this.menuService.savedItems = [];
  }

  navigateToItem(item: SavedItem) {
    this.menuService.currentItem = item.item;
    const base = this.router.url.split('/').slice(0, 3);
    this.router.navigate([...base, 'category', 'item']);
  }

  stopEvent(e: Event) {
    e.stopPropagation();
  }
}
