import { Component, HostListener } from '@angular/core';
import {
  faAngleLeft,
  faHeart as solidHeart,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { MenuService } from '../menu.service';
import { Item } from '../models/item.model';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Menu } from '../models/menu.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../models/category.model';
import { SavedItem } from '../models/savedItem.model';
import { SavedModifier } from '../models/savedModifier.model';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent {
  angleLeft = faAngleLeft;
  emptyHeart = faHeart;
  solidHeart = solidHeart;
  camera = faCamera;
  item: Item;
  itemsInFavorites: boolean = false;
  showSavedItemsOverlay: boolean = false;
  savedItems: SavedItem[] = [];
  menu: Menu | null = null;
  category: Category | null = null;
  savedModifiers: SavedModifier[] = [];
  itemSavedtimer: boolean = false;
  itemIsSaved: boolean;
  addPhotoModalOpen: boolean = false;
  selectedModifiers: Item[] = [];
  totalPrice: number = 0;
  staticMode: boolean;
  timer: number = 0;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected menuService: MenuService,
    protected location: Location,
    protected route: ActivatedRoute,
    protected toastr: ToastrService,
    protected router: Router,
    protected gtmService: GoogleTagManagerService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.menuService.currentMenu) {
      const menuId = this.route.snapshot.paramMap.get('restaurantId');
      if (menuId) {
        await this.menuService.retrieveMenuFromS3(menuId);
      }
    }

    this.menuService.savedItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((savedItems) => {
        this.savedItems = savedItems;

        if (
          savedItems.find((savedItem) => savedItem.item.id === this.item?.id)
        ) {
          this.itemIsSaved = true;
        } else {
          this.itemIsSaved = false;
        }
      });

    this.menuService.currentItem$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentItem) => {
        if (!currentItem) {
          const item = window.sessionStorage.getItem('currentItem');
          if (item) {
            currentItem = JSON.parse(item);
            if (currentItem) {
              this.item = currentItem;
            }
          } else {
            this.navigateBack();
          }
        } else {
          window.sessionStorage.setItem(
            'currentItem',
            JSON.stringify(currentItem)
          );
          this.item = currentItem;
        }
        this.totalPrice = this.item.price;
      });

    this.menuService.staticMode$.subscribe((mode) => {
      this.staticMode = mode;
    });

    setInterval(() => {
      if (this.staticMode) {
        this.menuService.resetExpandedCategoryies();
        clearInterval();
        this.navigateBack();
      }
    }, 90000);
  }

  ngOnDestroy(): void {
    clearInterval();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateBack() {
    this.location.back();
  }

  openSavedItemsOverlay() {
    this.showSavedItemsOverlay = true;
  }

  closeSavedItemsOverlay() {
    this.showSavedItemsOverlay = false;
  }

  resetTimer() {
    clearInterval();
    setInterval(() => {
      if (this.staticMode) {
        this.menuService.resetExpandedCategoryies();
        clearInterval();
        this.navigateBack();
      }
    }, 90000);
  }

  async saveItem() {
    if (this.item) {
      const filteredModifiers = this.savedModifiers.filter(
        (modifier) => modifier.childItems.length > 0
      );
      const savedItem: SavedItem = {
        item: this.item,
        savedModifiers: filteredModifiers,
        url: this.router.url,
      };
      const tag = {
        event: 'item_saved',
        item_id: this.item.id,
        item_name: this.item.name,
        restaurant_name: this.menuService.currentRestaurant,
      };
      this.gtmService.pushTag(tag);
      if (
        this.menuService.savedItems &&
        this.menuService.savedItems.length > 0
      ) {
        this.menuService.savedItems = [
          ...this.menuService.savedItems,
          savedItem,
        ];
      } else {
        this.menuService.savedItems = [savedItem];
      }
      this.toastr.success('Saved', '', {
        timeOut: 2500,
        positionClass: 'toast-top-center',
      });
      this.itemSavedtimer = true;
      await this.delay(2000);
      this.itemSavedtimer = false;
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  updateSavedModifier(modifier: SavedModifier): void {
    this.savedModifiers = this.savedModifiers.filter(
      (m) => m.name !== modifier.name
    );
    this.savedModifiers.push(modifier);
    this.totalPrice = this.item.price;
    this.savedModifiers.forEach((modifier) => {
      modifier.childItems.forEach((item) => {
        this.totalPrice += item.price;
      });
    });
  }

  minModifiersSelected(): boolean {
    return (
      this.savedModifiers.filter(
        (modifier) => modifier.quotaFulfilled === false
      ).length === 0
    );
  }

  openPhotoAddModal() {
    this.addPhotoModalOpen = true;
  }
  closeAddPhotoModule() {
    this.addPhotoModalOpen = false;
  }

  getPriceWithModifiers(): number {
    const modifierPrice = this.savedModifiers
      .map((modifier) =>
        modifier.childItems
          .map((item) => item.price)
          .reduce((total, price) => total + price)
      )
      .reduce((total, price) => total + price);
    return this.item.price + modifierPrice;
  }
}
