import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Category } from './models/category.model';
import { Item } from './models/item.model';
import { Menu } from './models/menu.model';
import * as S3 from 'aws-sdk/clients/s3';
import { SavedItem } from './models/savedItem.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _currentMenu = new BehaviorSubject<Menu | null>(null);
  currentMenu$ = this._currentMenu.asObservable();

  private _currentCategory = new BehaviorSubject<Category | null>(null);
  currentCategory$ = this._currentCategory.asObservable();

  private _currentItem = new BehaviorSubject<Item | null>(null);
  currentItem$ = this._currentItem.asObservable();

  private _currentRestaurant = new BehaviorSubject<string | null>(null);
  currentRestaurant$ = this._currentRestaurant.asObservable();

  private _restaurants = new BehaviorSubject<string[]>([]);
  restaurants$ = this._restaurants.asObservable();

  private _savedItems = new BehaviorSubject<SavedItem[]>([]);
  savedItems$ = this._savedItems.asObservable();

  private categoryHistory: Category[] = [];

  private scrollPostionHistory: number[] = [];

  private _returnToHome = new BehaviorSubject<boolean>(false);
  returnToHome$ = this._returnToHome.asObservable();

  scrollToY: number = 0;

  private _expandedCategories = new BehaviorSubject<string[]>([]);
  expandedCategories$ = this._expandedCategories.asObservable();

  private staticMode = new BehaviorSubject<boolean>(false);
  staticMode$ = this.staticMode.asObservable();
  staticModeValue = this.staticMode.value;

  constructor(protected router: Router) {
    const savedItems = sessionStorage.getItem('savedItems');
    if (savedItems) {
      const parsed = JSON.parse(savedItems);
      if (parsed.expiry > Date.now()) {
        this.savedItems = parsed.value;
      }
    }
  }

  setStaticMode() {
    this.staticMode.next(true);
  }

  addScrollPosition(pos: number) {
    this.scrollPostionHistory.push(pos);
  }

  navigatedBack(): number {
    const pos = this.scrollPostionHistory.pop();
    return pos ? pos : 0;
  }

  get savedItems(): SavedItem[] {
    return this._savedItems.getValue();
  }

  set savedItems(savedItems: SavedItem[]) {
    const now = new Date();
    const item = {
      value: savedItems,
      expiry: now.getTime() + 3600000,
    };
    sessionStorage.setItem('savedItems', JSON.stringify(item));
    this._savedItems.next(savedItems);
  }

  get currentMenu(): Menu | null {
    return this._currentMenu.getValue();
  }

  set currentMenu(menu: Menu | null) {
    this._currentMenu.next(menu);
  }

  set returnToHome(bool: boolean) {
    this._returnToHome.next(bool);
  }

  get currentCategory(): Category | null {
    return this._currentCategory.getValue();
  }

  set currentCategory(category: Category | null) {
    this._currentCategory.next(category);
    if (category) {
      this.categoryHistory.push(category);
    }
  }

  get currentItem(): Item | null {
    return this._currentItem.getValue();
  }

  set currentItem(item: Item | null) {
    this._currentItem.next(item);
  }

  get currentRestaurant(): string | null {
    return this._currentRestaurant.getValue();
  }

  set currentRestaurant(id: string | null) {
    this._currentRestaurant.next(id);
  }

  get restaurants(): any[] {
    return this._restaurants.getValue();
  }

  set restaurants(data: any[]) {
    this._restaurants.next(data);
  }

  get expandedCategories(): string[] {
    return this._expandedCategories.getValue();
  }

  addExpandedCategory(id: string) {
    let current = this._expandedCategories.getValue();
    current.push(id);
    this._expandedCategories.next(current);
  }

  removeExpandedCategory(id: string) {
    let current = this._expandedCategories.getValue();
    let removed = current.filter((cat) => cat !== id);
    this._expandedCategories.next(removed);
  }

  resetExpandedCategoryies() {
    this._expandedCategories.next([]);
  }

  async retrieveMenuFromS3(id: string): Promise<void> {
    const s3 = new S3({
      credentials: {
        accessKeyId: 'AKIAYZFSYW52NZQVIROO',
        secretAccessKey: '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',
      },
      region: 'us-west-1',
    });

    const params = {
      Bucket: 'grandemenudata',
      Key: id + '.json',
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        this.currentRestaurant = null;
        this.router.navigateByUrl('');
        return;
      }
      this.currentRestaurant = id;
      if (data.Body) {
        const restaurant = JSON.parse(data.Body?.toString());
        this.currentMenu = restaurant;
      }
      return;
    });
  }

  returnToPreviousCategory(): boolean {
    if (this.categoryHistory.length > 1) {
      this.categoryHistory.pop();
      const length = this.categoryHistory.length;
      this._currentCategory.next(this.categoryHistory[length - 1]);
      return true;
    }
    this.categoryHistory.pop();
    this._currentCategory.next(null);
    return false;
  }

  async getAllAvailableRestaurants(): Promise<any[]> {
    const s3 = new S3({
      credentials: {
        accessKeyId: 'AKIAYZFSYW52NZQVIROO',
        secretAccessKey: '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',
      },
      region: 'us-west-1',
    });

    const params = {
      Bucket: 'grandemenudata',
      Key: 'directory.json',
    };

    let restaurants: any[] = [];

    s3.getObject(params, (err, data) => {
      if (err) {
        return;
      }
      if (data.Body) {
        const res = JSON.parse(data.Body?.toString()).restaurants;
        this.restaurants = res;
      }
    });

    return restaurants;
  }
}
