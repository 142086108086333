import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Item } from 'src/app/menu/models/item.model';
import { Menu } from 'src/app/menu/models/menu.model';
import { Modifier } from 'src/app/menu/models/modifier.model';
import { Category } from '../../menu/models/category.model';

@Component({
  selector: 'app-formatter',
  templateUrl: './formatter.component.html',
  styleUrls: ['./formatter.component.scss'],
})
export class FormatterComponent {
  @ViewChild('f')
  dataForm?: NgForm;

  formattedData = '';

  formatData() {
    const jsonData = JSON.parse(this.dataForm?.value.json);

    const dataObj: Menu = {
      store: this.formatStore(this.dataForm?.value.name),
      id: jsonData.id,
      categories: this.categoriesFormatter(jsonData.sections, jsonData.id),
      introMessage: '',
      externalLink: {
        buttonText: '',
        linkAddress: '',
      },
    };

    this.formattedData = JSON.stringify(dataObj);
  }

  formatStore(name: string) {
    return {
      logo: '',
      name: name,
    };
  }

  categoriesFormatter(categories: WFCategory[], id?: string): Category[] {
    console.log('sections', categories);
    return categories.map((category) => {
      return {
        id: category.id,
        name: category.name,
        description: category.description,
        image: '',
        sub_categories: [],
        items: this.itemFormatter(category.items, category.id),
        parent_id: id || '',
      };
    });
  }

  itemFormatter(items: WFItem[], id: string): Item[] {
    return items.map((item) => {
      return {
        id: item.id,
        name: item.name,
        price: item.price / 100,
        description: item.description,
        number: item.number,
        tags: item.tags,
        image: '',
        child_modifiers: this.modifiersFormatter(item.child_modifiers, item.id),
        parent_id: id,
      };
    });
  }

  modifiersFormatter(modifiers: WFChildModifier[], id: string): Modifier[] {
    return modifiers.map((modifier) => {
      return {
        id: modifier.id,
        name: modifier.name,
        min: modifier.min_selection,
        max: modifier.max_selection,
        child_items: this.itemFormatter(modifier.child_items, modifier.id),
        parent_id: id,
      };
    });
  }
}

interface Store {
  name: string;
}

interface WFMenu {
  store: Store;
  categories: WFCategory[];
}

interface WFCategory {
  id: string;
  name: string;
  description: string;
  child_sections: WFCategory[];
  items: WFItem[];
  __typename: string;
}

interface WFItem {
  id: string;
  client_id: string;
  name: string;
  price: number;
  description: string;
  number?: string;
  tags: string[];
  child_modifiers: WFChildModifier[];
  __typename: string;
}

interface WFChildModifier {
  id: string;
  client_id: string;
  name: string;
  min_selection: number;
  max_selection: number;
  child_items: WFItem[];
  __typename: string;
  description: string;
}
