<div class="container" (click)="stopPropagation($event)">
  <div class="close" (click)="closeAddPhotoModal($event)">X</div>
  <div *ngIf="!successfulUpload" class="title">
    Have a picture of this item?
  </div>
  <div *ngIf="!successfulUpload" class="content">
    Click below to take a photo, or select an image from your photo library.
    Your photo submissions help to improve the quality of our menu.
    <input
      type="file"
      accept="image/*"
      id="image"
      (change)="imageChanged($event)"
    />
  </div>
  <div
    *ngIf="selectedImage && !successfulUpload && !loading"
    class="upload"
    (click)="uploadImage()"
  >
    upload image
  </div>
  <img class="loading" *ngIf="loading" src="../../../assets/loading-bw.gif" />
  <div *ngIf="successfulUpload" class="post-submit-info">
    Thank you for your submission. Restaurant management will review your photo
    shortly.
  </div>
</div>
