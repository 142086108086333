<div
  *ngFor="let category of currentCategories"
  class="category-row"
  (click)="selectCategory(category)"
>
  <div class="content">
    <div class="category-title">{{ category.name }}</div>
    <div class="category-description">{{ category.description }}</div>
  </div>

  <!-- <img
    class="category-image"
    *ngIf="category.image && category.image.length > 0"
    [src]="category.image"
  /> -->
</div>
<div
  *ngFor="let item of currentItems"
  class="item-row"
  (click)="selectItem(item)"
>
  <div class="item-row-header">
    <div class="item-title">{{ item.name }}</div>
    <div class="item-price" *ngIf="item && item.price > 0">
      ${{ item.price | number: "1.2" }}
    </div>
  </div>
  <div class="item-row-description">
    <div class="item-description">{{ item.description }}</div>
    <img
      *ngIf="item.image && item.image.length > 0"
      class="item-image"
      [src]="item.image"
    />
  </div>
</div>
