<div class="landing">
  <h1>GRANDE MENUS</h1>
  <p>Here are our currently available menus:</p>
  <div
    *ngFor="let restaurant of restaurants"
    (click)="routeToMenu(restaurant.url)"
    class="name"
  >
    <div class="name" *ngIf="restaurant.name">
      {{ restaurant.name }}
    </div>
  </div>
</div>
