import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormatterComponent } from './formatter/formatter/formatter.component';
import { CategoryComponent } from './menu/category/category.component';
import { HomeComponent } from './menu/home/home.component';
import { ItemComponent } from './menu/item/item.component';
import { LandingComponent } from './menu/landing/landing.component';
import { InstructionsComponent } from './instructions/instructions.component';

const routes: Routes = [
  {
    path: 'directory',
    component: LandingComponent,
  },
  {
    path: '',
    component: InstructionsComponent,
  },
  {
    path: 'format',
    component: FormatterComponent,
  },
  {
    path: ':restaurantId/menu',
    component: HomeComponent,
  },
  {
    path: ':restaurantId/menu',
    component: HomeComponent,
  },
  {
    path: ':restaurantId/menu/category/item',
    component: ItemComponent,
  },
  {
    path: ':restaurantId/menu/item',
    component: ItemComponent,
  },
  {
    path: ':restaurantId',
    redirectTo: ':restaurantId/menu',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
