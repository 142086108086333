import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  s3 = new AWS.S3({
    accessKeyId: 'AKIAYZFSYW52G2VCYQLX',
    secretAccessKey: '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',
    region: 'us-west-1',
  });

  BUCKET_NAME = 'grandeimages';

  async uploadFile(
    restaurantId: string,
    itemId: string,
    imageData: any
  ): Promise<string> {
    const extension = imageData.name.split('.').pop();
    const fileName: string = v4();

    const params = {
      Bucket: this.BUCKET_NAME,
      Key: `${restaurantId}/${itemId}/${fileName}.${extension}`,
      Body: imageData,
    };

    const response = await this.s3.upload(params).promise();

    return response.Location;
  }
}
