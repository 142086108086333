import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Category } from '../models/category.model';
import { MenuService } from '../menu.service';
import { Item } from '../models/item.model';
import { CategoryComponent } from '../category/category.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-content-combiner',
  templateUrl: './content-combiner.component.html',
  styleUrls: ['./content-combiner.component.scss'],
})
export class ContentCombinerComponent implements OnInit, OnDestroy {
  currentCategories: Category[] = [];
  currentItems: Item[] = [];

  topOfMenu = true;

  private unsubscribe$ = new Subject<void>();

  @Output()
  categorySelected: EventEmitter<Category> = new EventEmitter<Category>();

  @Output()
  itemSelected: EventEmitter<Item> = new EventEmitter<Item>();

  constructor(protected menuService: MenuService, protected router: Router) {}

  ngOnInit(): void {
    this.menuService.currentCategory$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((category) => {
        if (category) {
          this.topOfMenu = false;
          this.currentCategories = category.sub_categories;
          this.currentItems = category.items;
        } else if (window.sessionStorage.getItem('currentCategory')) {
          const categoryString =
            window.sessionStorage.getItem('currentCategory');
          if (categoryString) {
            this.currentCategories = JSON.parse(categoryString).sub_categories;
          }
        } else {
          this.topOfMenu = true;
        }
      });
    this.menuService.currentMenu$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menu) => {
        if (this.topOfMenu && menu) {
          this.currentCategories = menu.categories;
          this.currentItems = [];
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectItem(item: Item) {
    window.sessionStorage.setItem('currentItem', JSON.stringify(item));
    this.menuService.currentItem = item;
    this.itemSelected.emit(item);
  }

  selectCategory(category: Category) {
    window.sessionStorage.setItem('currentCategory', JSON.stringify(category));
    this.menuService.currentCategory = category;
    this.categorySelected.emit(category);
  }
}
