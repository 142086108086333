<div class="modifier-top">
  <div class="title">{{ modifier?.name }}</div>
  <div class="count">
    <div
      *ngIf="modifier && (modifier.min > 0 || modifier.max > 0)"
      [ngClass]="{ error: savedChildItems.length < modifier.min }"
    >
      Choose
      <span
        *ngIf="
          modifier &&
          modifier.min > 0 &&
          modifier.max > 0 &&
          modifier.max !== modifier.min
        "
      >
        {{ modifier.min }} - {{ modifier.max }}
      </span>
      <span *ngIf="modifier && modifier.min > 0 && modifier.max < 1">
        at least {{ modifier.min }}
      </span>
      <span *ngIf="modifier && modifier.min < 1 && modifier.max > 0">
        up to {{ modifier.max }}
      </span>
      <span
        *ngIf="
          modifier &&
          modifier.min > 0 &&
          modifier.max > 0 &&
          modifier.min === modifier.max
        "
      >
        {{ modifier.min }}
      </span>
    </div>
  </div>
</div>

<div class="child-items-container" *ngIf="modifier">
  <app-child-item
    *ngFor="let item of modifier.child_items"
    class="child-item"
    [item]="item"
    (itemDeselectedEvent)="itemDeselected($event)"
    (itemSelectedEvent)="itemSelected($event)"
    [allowNewSelections]="savedChildItems.length < modifier.max"
  >
  </app-child-item>
</div>
