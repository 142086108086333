<div class="overlay">
  <div class="saved-items-overlay" (click)="closeOverlay($event)">
    <div class="saved-items-content" (click)="stopEvent($event)">
      <div class="top-row">
        <div class="title">Saved items</div>
        <div class="close-button" (click)="closeOverlay($event)">
          <div>X</div>
        </div>
      </div>

      <div class="notifier" *ngIf="savedItems.length < 1">
        No items are currently saved. Click on the star on an item page to save
        it and see it here.
      </div>
      <div class="items">
        <div *ngIf="savedItems.length > 0" class="border-top"></div>
        <div
          class="saved-item"
          *ngFor="let savedItem of savedItems; index as i"
        >
          <div class="item" (click)="navigateToItem(savedItem)">
            <div class="title-row">
              <img
                class="image"
                [src]="savedItem.item.image"
                *ngIf="savedItem.item.image"
              />
              <div
                class="image-placeholder"
                *ngIf="!savedItem.item.image"
              ></div>
              <div class="info" *ngIf="savedItem.item">
                <span class="name">{{ savedItem.item.name }}</span>
                <span *ngIf="savedItem.item.price > 0">
                  ${{ savedItem | priceWithModifiers }}
                </span>
              </div>
              <div class="right-column">
                <fa-icon
                  class="remove"
                  [icon]="timesCircle"
                  (click)="removeItem(i)"
                ></fa-icon>
              </div>
            </div>
            <div class="content-row">
              <div class="modifiers">
                <div
                  *ngFor="let modifier of savedItem.savedModifiers"
                  class="child-items"
                >
                  <div
                    *ngFor="let item of modifier.childItems"
                    class="item-name"
                  >
                    - {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear-all" (click)="clearAll()" *ngIf="savedItems.length > 0">
        Clear all items
      </div>
    </div>
  </div>
</div>
