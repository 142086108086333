<div class="header" *ngIf="!staticMode && savedItems && savedItems.length > 0">
  <div class="container">
    <div
      *ngIf="!staticMode && savedItems && savedItems.length > 0"
      class="saved"
      (click)="openSavedItemsOverlay()"
    >
      <fa-icon [icon]="solidHeart"></fa-icon>
    </div>
  </div>
</div>
<div class="home" (touchstart)="resetTimer()">
  <div class="top">
    <div class="logo-container">
      <img
        class="logo"
        *ngIf="menuData && menuData.store && menuData.store.logo.length > 0"
        [src]="menuData.store.logo"
      />
      <h1
        *ngIf="
          !menuData ||
          !menuData.store ||
          !menuData.store.logo ||
          !(menuData.store.logo.length > 0)
        "
        class="restaurant-name"
      >
        {{ menuData?.store?.name }}
      </h1>
    </div>
    <div
      class="external-link"
      *ngIf="
        !staticMode &&
        menuData?.externalLink?.buttonText &&
        menuData?.externalLink?.linkAddress
      "
    >
      <a
        [href]="menuData?.externalLink?.linkAddress"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
        >{{ menuData?.externalLink?.buttonText }}</a
      >
    </div>

    <div *ngIf="returnToHome" class="return-home" (click)="returnToHomeMenu()">
      &#60; Back
    </div>

    <div *ngIf="introMessage" class="info">
      {{ introMessage }}
    </div>
  </div>

  <div *ngIf="hasSubCategories" class="content">
    <app-content-combiner
      (categorySelected)="navigateToCategory($event)"
    ></app-content-combiner>
  </div>
  <div *ngIf="!hasSubCategories && !currentCategory" class="content">
    <div *ngFor="let category of menuData?.categories">
      <app-category [category]="category"></app-category>
    </div>
  </div>
  <div *ngIf="!hasSubCategories && currentCategory" class="content">
    <div *ngFor="let category of currentCategory.sub_categories">
      <app-category [category]="category"></app-category>
    </div>
  </div>
</div>
<app-saved-items
  *ngIf="showSavedItemsOverlay"
  (closeOverlayEvent)="closeSavedItemsOverlay()"
></app-saved-items>
