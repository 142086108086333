import { Pipe, PipeTransform } from '@angular/core';
import { SavedItem } from '../models/savedItem.model';

@Pipe({
  name: 'priceWithModifiers',
})
export class PriceWithModifiersPipe implements PipeTransform {
  transform(item: SavedItem): number {
    const basePrice = item.item.price;

    if (item.savedModifiers.length > 0) {
      const modPrice: number = item.savedModifiers
        .map((modifier) => {
          if (modifier.childItems.length > 0) {
            return modifier.childItems
              .map((item) => item.price)
              .reduce((prev, current) => prev + current);
          }
          return 0;
        })
        .reduce((prev, current) => prev + current);

      return basePrice + modPrice;
    }

    return basePrice;
  }
}
